import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import gsap from "gsap";
import SEO from "../seo";
import creative from "../../images/service-creative-bw.png";
import creativeColor from "../../images/service-creative.png";
import Button from "../ButtonComponent";
import RequestPortfolio from "../RequestPortfolio";
import RequestProposal from "../RequestProposal";
import SiteAudit from "../SiteAudit";

import bottomDesign from "../../images/servicebottom.png";

import bottomDesignMobile from "../../images/bottomdesigns/services-mobile.png";

import "./style.scss";

export default function Services() {
  const [showPortfolioForm, setShowPortfolioForm] = useState(false);
  const [showSiteAuditForm, setShowSiteAuditForm] = useState(false);
  const [showProposalForm, swetShowProposalForm] = useState(false);

  function togglePortfolioform() {
    setShowPortfolioForm(!showPortfolioForm);
  }

  function toggleSiteAuditForm() {
    setShowSiteAuditForm(!showSiteAuditForm);
  }

  function toggleProposalForm() {
    swetShowProposalForm(!showProposalForm);
  }

  useEffect(() => {
    gsap.from(".textanim", {
      // scale: 5,
      x: -200,
      delay: 0.4,
      duration: 1,
      skewX: 20,
      stagger: 0.3,
      opacity: 0,
      ease: "sine.out",
    });
  }, []);

  return (
    <Layout>
      <SEO
        title="Services | Complete Media Solutions"
        description="Bespoke strategic marketing services and digital, electronic and print media solutions to develop and amplify cohesive brand experiences.	"
        img="https://blackelephantdigital.com/assets/linkpreviews/main.jpg"
      />
      <div className="services">
        <RequestPortfolio
          isFormActive={showPortfolioForm}
          closePopup={togglePortfolioform}
        />
        <RequestProposal
          isFormActive={showProposalForm}
          closePopup={toggleProposalForm}
        />

        <SiteAudit
          isFormActive={showSiteAuditForm}
          closePopup={toggleSiteAuditForm}
        />
        <div className="services__hero">
          <h1 className="services__heading">
            <div className="textanim">360˚ Strategic</div>
            <div className="textanim">Marketing</div>

            <div className="textanim">for Cohesive </div>
            <div className="textanim" style={{ marginBottom: "20px" }}>
              Brands
            </div>
            {/* <br /> */}
            <span className="services__hero__btns textanim">
              <Button
                className="btn-light"
                text="free site audit"
                fillColor="#3DB2FF"
                onClick={toggleSiteAuditForm}
              />

              <Button
                className="btn-light"
                text="request portfolio"
                fillColor="#FAD049"
                onClick={togglePortfolioform}
              />

              {/* <button className="btn-light">request portfolio</button> */}
            </span>
          </h1>
          <div className="services__hero__images">
            <img
              className="services__hero__img services__hero__img--cb"
              src={creative}
            ></img>
            <img
              className="services__hero__img services__hero__img--cf"
              src={creativeColor}
            ></img>
          </div>

          {/* <img className="services__hero__img--color" src={eleColor}></img> */}

          <img
            className="services__hero__bottom desktop"
            src={bottomDesign}
          ></img>
          <img
            className="services__hero__bottom mobile"
            src={bottomDesignMobile}
          ></img>
        </div>

        <div id="services" className="services__section1 moveupanim1">
          <h2 className="services__section1__heading moveupanim">
            Digital Marketing Services{" "}
          </h2>

          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-x-20 gap-y-10 services__section1__container moveupanim">
            <div className="servicesection__container__col">
              <div className="services__section1__service">
                <div className="services__section1__service__title">
                  Brand Identity & Design{" "}
                </div>
                <div className="services__section1__service__item">
                  Logo Development
                </div>
                <div className="services__section1__service__item">
                  Brand Strategy & Architecture
                </div>
                <div className="services__section1__service__item">
                  Strategic Marketing Plans
                </div>
                <div className="services__section1__service__item">
                  Print Collaterals
                </div>
                <div className="services__section1__service__item">
                  Responsive Website Design
                </div>
                <div className="services__section1__service__item">
                  UI/ UX Optimization
                </div>
              </div>
            </div>
            <div className="servicesection__container__col">
              <div className="services__section1__service">
                <div className="services__section1__service__title">
                  Digital Journey Development
                </div>
                <div className="services__section1__service__item">
                  Website Development
                </div>
                <div className="services__section1__service__item">
                  Interactive Prototyping
                </div>
                <div className="services__section1__service__item">
                  App Development
                </div>
                <div className="services__section1__service__item">
                  E-Commerce Solutions
                </div>
                <div className="services__section1__service__item">
                  Social Media Management
                </div>
                <div className="services__section1__service__item">
                  Email Marketing
                </div>
                <div className="services__section1__service__item">
                  Search Marketing
                </div>
              </div>
            </div>
            <div className="servicesection__container__col">
              <div className="services__section1__service">
                <div className="services__section1__service__title">Studio</div>
                <div className="services__section1__service__item">
                  Storyboarding
                </div>
                <div className="services__section1__service__item">
                  Audio Production
                </div>
                <div className="services__section1__service__item">
                  Videography
                </div>
                <div className="services__section1__service__item">
                  2D/ 3D Animation
                </div>
              </div>
            </div>
          </div>
          <br />
          <center>
            <Button
              className="btn-dark"
              text="schedule a meeting"
              targetBlank
              url="https://cal.com/black-elephant-digital/15min"
              fillColor="#7AB4B7"
              isATag
            />
          </center>
        </div>

        <div className="services__section2">
          <h2 className="services__section2__heading moveupanim">
            Print & Electronic Media
          </h2>

          <div className="services__section2__text">
            Completing the 360˚ media bouquet to provide a complete brand
            experience and complete media management.
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-x-20 gap-y-10 services__section2__container moveupanim">
            <div className="servicesection__container__col">
              <div className="services__section2__service">
                <div className="services__section2__service__title">
                  Television
                </div>
                <div className="services__section2__service__item">
                  Commercials
                </div>
                <div className="services__section2__service__item">
                  Advertorials
                </div>
                <div className="services__section2__service__item">
                  Sponsorships
                </div>
                <div className="services__section2__service__item">
                  L Brands/ Sky Panels
                </div>
              </div>
            </div>
            <div className="servicesection__container__col">
              <div className="services__section2__service">
                <div className="services__section2__service__title">RADIO</div>
                <div className="services__section2__service__item">
                  Commercials
                </div>
                <div className="services__section2__service__item">
                  RJ Mentions
                </div>
                <div className="services__section2__service__item">Sweeps</div>
                <div className="services__section2__service__item">
                  Contents
                </div>
                <div className="services__section2__service__item">
                  Sponsored Programmes
                </div>
              </div>
            </div>
            <div className="servicesection__container__col">
              <div className="services__section2__service">
                <div className="services__section2__service__title">Print</div>
                <div className="services__section2__service__item">
                  Advertorials
                </div>
                <div className="services__section2__service__item">Ads</div>
                <div className="services__section2__service__item">
                  Branded Content
                </div>
              </div>
            </div>
          </div>

          <div className="services__section2__btns">
            <Button
              className="btn-light"
              text="request for proposal"
              fillColor="#B85076"
              onClick={toggleProposalForm}
            />

            <Button
              className="btn-light"
              text="group legacy"
              fillColor="#9D84B7"
              url="http://cdcsgroup.com"
              targetBlank
              isATag
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
