import React, { useState } from "react";
import "./style.scss";
import axios from "axios";
import spinner from "../../images/Spinner-1s-200px.gif";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  organization: "",
};

export default function RequestProposal({
  isFormActive,
  closePopup = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState(initialState);

  async function onFormSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://blackelephantdigital.com/api/public/index.php/proposal-request",
        data
      );

      setSuccess(true);
      setIsLoading(false);
      setData(initialState);
      setTimeout(() => {
        setSuccess(false);
        closePopup();
      }, 5000);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  }

  function handleInputChange(e) {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  return (
    <div>
      <div
        className={`portfolio-popup__blur ${
          isFormActive && "portfolio-popup__blur--active"
        }`}
      ></div>
      <div
        className={`portfolio-popup ${
          isFormActive && "portfolio-popup--active"
        }`}
      >
        <button onClick={closePopup} className="portfolio-popup__close">
          <i className="fas fa-times"></i>
        </button>
        <form onSubmit={onFormSubmit}>
          <h4 style={{ textAlign: "center" }}>
            <strong style={{ fontWeight: "700" }}>REQUEST PROPOSAL</strong>
            {/* <hr /> */}
          </h4>
          <div className="row">
            <div className="col col-12 col-md-6">
              <input
                className="portfolio-popup__input"
                name="firstName"
                placeholder="First Name"
                value={data.firstName}
                onChange={handleInputChange}
                required
              ></input>
            </div>
            <div className="col col-12 col-md-6">
              <input
                className="portfolio-popup__input"
                name="lastName"
                placeholder="Last Name"
                onChange={handleInputChange}
                value={data.lastName}
                required
              ></input>
            </div>
          </div>
          <input
            type="email"
            className="portfolio-popup__input"
            name="email"
            placeholder="Email Address"
            value={data.email}
            onChange={handleInputChange}
            required
          ></input>
          <input
            className="portfolio-popup__input"
            name="organization"
            placeholder="Organization"
            value={data.organization}
            onChange={handleInputChange}
            required
          ></input>

          <center>
            <button disabled={isLoading} className="btn-light">
              submit
            </button>
            {isLoading && (
              <img
                style={{
                  width: "40px",
                  height: "40px",
                  display: "inline-block",
                }}
                src={spinner}
              ></img>
            )}
          </center>
          {success && (
            <div className="portfolio-popup__success">
              Thank you! your request has been recorded.
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
